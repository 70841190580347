<template>
    <div class="video-container">
        <div class="video-seat">
            <el-row type="flex" align="middle">
                <el-col :span="12">
                    <div class="elevator-name-span">{{ elevator && elevator['v_elevator_name'] }}</div>
                </el-col>
                <el-col :span="12">
                    <div class="btn-block" :style="{color: (isRecording ? 'red' : '')}" @click="isRecording ? stopRecord() : startRecord()">
                        <img :src="require('@/assets/ic_rec.png')" alt="" />开始录屏
                    </div>
                    <div class="btn-block" :style="{color: (isTalking ? 'red' : '')}" @click="isTalking ? stopTalk() : startTalk()">
                        <img :src="require('@/assets/ic_phone.png')" alt="" />发起对讲
                    </div>
                </el-col>
            </el-row>
            <div class="options" v-if="showMonitor">
                <!--         <div class="item active">
                    <img v-if="elevatorModuleInfo && elevatorModuleInfo.onLine === 0" src="@/assets/icon_offline_gray.png" />
                    <img v-else-if="elevatorModuleInfo && elevatorModuleInfo.onLine != 0" src="@/assets/bigscreen/ruijin/icon_online_green.png" />
                    <div class="title">{{ elevatorModuleInfo ? (elevatorModuleInfo.onLine === 0 ? '离线' : '在线') : '--' }}</div>
                </div> -->
                <div class="item">
                    <div v-if="monitorPageDisplay.floor.value" style="font-weight: bold; color: #3CD500;">{{ monitorPageDisplay.floor.value }}</div>
                    <div v-else style="font-weight: bold; color: #3CD500;">--</div>
                    <div class="title">楼层</div>
                </div>
                <div :class="monitorPageDisplay.direction && monitorPageDisplay.direction.value === 1 ? 'item' : 'item active'">
                    <img v-if=" monitorPageDisplay.direction.value === 0" src="@/assets/icon_park_green.png" />
                    <img v-else-if="monitorPageDisplay.direction.value === 1" src="@/assets/icon_up_yellow.png" />
                    <img v-else-if="monitorPageDisplay.direction.value === 2" src="@/assets/icon_down_yellow.png" />
                    <div v-else style="font-weight: bold; color: #3CD500;">--</div>
                    <div class="title">方向</div>
                </div>
                <div :class="monitorPageDisplay.floorStatus && monitorPageDisplay.floorStatus.value === 1 ? 'item' : 'item active'">
                    <img v-if="monitorPageDisplay.floorStatus.value === 1" src="@/assets/icon_pingc.png" />
                    <img v-else-if="monitorPageDisplay.floorStatus.value === 0" src="@/assets/icon_feipc.png" />
                    <div v-else style="font-weight: bold; color: #3CD500;">--</div>
                    <div class="title">平层状态</div>
                </div>
                <div class="item">
                    <img v-if="dangmen" src="@/assets/icon_alert.png" />
                    <img v-if="!dangmen" src="@/assets/icon_right_green.png" />
                    <div class="title">阻挡门</div>
                </div>
            </div>
        </div>
        <div class="video-poster" @click="startMonitor" v-if="!isVideoInit">
            <img :src="require('@/assets/ic_eye.png')" alt="">
        </div>
        <div :id="domContainer" class="video-zone"></div>
    </div>
</template>
<script>
import { Loading } from 'element-ui';
import videoKit from '@/common/utils/videoKit/index'
import { getElevatorLiveUrl, getElevatorMonitorUrlEzopenToken } from '@/api/monitor/index'
import Monitor from '@/pages/monitor/monitor'
export default {
    props: {
        elevator: {
            type: Object,
            required: false
        },
        num: {
            type: Number,
            required: false
        },
        showMonitor: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            haikangCamera: true,
            isTalking: false,
            isVideoOpen: false,
            currentCamera: {},
            privateUrlToken: '',
            showBtns: false,
            monitor_showloding_flag: false,
            monitorVidoeFlag: false,
            monitorRtmp: true,
            monitorHls: false,
            isVideoInit: false,
            domContainer: `video-${this.num}`,
            isRecording: false,
            dangmen: false,
            loadingInstance: null
        }
    },
    mixins: [Monitor],
    mounted() {
        let elementResizeDetectorMaker = require("element-resize-detector");
        //监听元素变化
        let erd = elementResizeDetectorMaker();
        let that = this;
        erd.listenTo(document.getElementById(this.domContainer), function(element) {
            // console.log(element.offsetWidth)
            let video = document.getElementById('EZUIKitPlayer-video-0')
            if (video)
                video.style = `width:${element.offsetWidth}px; height:${element.offsetHeight}px;`
            video = document.getElementById('EZUIKitPlayer-video-1')
            if (video)
                video.style = `width:${element.offsetWidth}px; height:${element.offsetHeight}px;`
            video = document.getElementById('EZUIKitPlayer-video-2')
            if (video)
                video.style = `width:${element.offsetWidth}px; height:${element.offsetHeight}px;`
            video = document.getElementById('EZUIKitPlayer-video-3')
            if (video)
                video.style = `width:${element.offsetWidth}px; height:${element.offsetHeight}px;`
            video = document.getElementById('EZUIKitPlayer-video-4')
            if (video)
                video.style = `width:${element.offsetWidth}px; height:${element.offsetHeight}px;`
            video = document.getElementById('EZUIKitPlayer-video-5')
            if (video)
                video.style = `width:${element.offsetWidth}px; height:${element.offsetHeight}px;`
            video = document.getElementById('EZUIKitPlayer-video-6')
            if (video)
                video.style = `width:${element.offsetWidth}px; height:${element.offsetHeight}px;`

            // let videoiFrame = document.getElementById('EZUIKitPlayer-video-0')
            // debugger

        })
    },
    methods: {
        getElevatorCode() {
            return this.currentCamera.vElevatorCode
        },
        startMonitor() {
            getElevatorLiveUrl({ elevatorId: this.elevator['v_elevator_id'] }).then(res => {
                if (res.code === '0000') {
                    if (this.showMonitor) {
                        this.closeConnection()
                        if (res.info)
                            this.initSocket(res.info.vElevatorCode, ['CarRoof', 'MontorRoom', 'SINGLEBOX'])
                    }

                    this.currentCamera = res.info
                    if (!res.info) {
                        return this.$Message.error('此电梯未安装物联网智能摄像头')
                    }
                    this.monitor_showloding_flag = true
                    if (this.currentCamera.iCameraType == 1) {
                        let that = this
                        this.haikangCamera = true
                        this.monitorVidoeFlag = !this.monitorVidoeFlag
                        videoKit.initDisplay(this.domContainer, this.privateUrlToken, this.currentCamera.vCloudNumber, {}, (result, data) => {
                            this.loadingInstance.close()
                            this.$Message.error('播放失败,请重试')
                        }).then((player) => {
                            that.isVideoInit = true
                            videoKit.pickPlayer(this.domContainer);


                            // 跨域
                            // for (var i = 0; i < window.frames.length; i++) {
                            //     let frame = window.frames[i];
                            
                            //     let fs = frame.contentDocument.document.getElementById("fullScreen");

                            //     if (fs)
                            //         fs.style = "display:none!important;"
                            // }
                        })
                    } else {
                        this.haikangCamera = false
                        if (res.data.info.vPrivateUrl) {
                            this.privateUrl = `https://open.ys7.com/ezopen/h5/iframe_se?url=${res.data.info.vPrivateUrl}&accessToken=${this.privateUrlToken}&begin=2020061922&end=20200619`
                            this.monitorVidoeFlag = !this.monitorVidoeFlag
                            this.monitorRtmp = false
                            setTimeout(() => {
                                this.monitor_showloding_flag = false
                            }, 3500)
                            return
                        }
                        if (res.data.info.vHlsUrl) {
                            this.monitorRtmp = false
                            this.monitorHls = true
                            this.monitorVidoeFlag = !this.monitorVidoeFlag
                            this.mxVideo.mxVideoInit()
                            this.mxVideo.shakeHand(this, res.data.info.vHlsUrl, this.$refs.curHLSPlayer)
                            return
                        }
                        this.monitor_showloding_flag = false
                        return this.$Message.error('此电梯未安装物联网智能摄像头')
                    }
                } else {
                    this.monitor_showloding_flag = false
                    return this.$Message.error('此电梯未安装物联网智能摄像头');
                    // this.mxVideo.mxVideoInit()
                }
            }).catch((err) => {
                this.loadingInstance.close()
            })
        },
        startTalk() {
            // if(!this.isVideoOpen)
            //     return this.$Message.error("请先打开视频！");
            if (this.haikangCamera)
                videoKit.startTalk().then(() => {
                    this.isTalking = true
                })
        },
        stopTalk() {
            if (this.haikangCamera)
                videoKit.stopTalk().then(() => {
                    this.isTalking = false
                })
        },
        // playVideo() {
        //     let that = this
        //     if (that.haikangCamera)
        //         if (videoKit.checkPlayer()) {
        //             videoKit.changeVideoUrl(that.currentCamera.vCloudNumber)
        //             that.isVideoOpen = true
        //             setTimeout(function() {
        //                 that.stopVideo()
        //             }, 120000)
        //         } else {
        //             videoKit.playVideo().then(() => {
        //                 that.isVideoOpen = true
        //             })
        //             setTimeout(function() {
        //                 that.stopVideo()
        //             }, 120000)
        //         }
        // },
        stopVideo() {
            if (this.haikangCamera)
                videoKit.stopVideo().then(() => {
                    this.isVideoOpen = false
                    this.isVideoInit = false
                    this.isTalking = false
                    this.isRecording = false
                })
        },
        changeVideoUrl() {
            videoKit.changeVideoUrl(this.currentCamera.vCloudNumber).then(() => {
                this.isVideoOpen = true
                this.loadingInstance.close()
            }).catch((err) => {
                this.loadingInstance.close()
            })
        },
        destroyVideo() {
            videoKit.destroy()
            this.isVideoInit = false
        },
        startRecord() {
            // if(!this.isVideoOpen) {
            //     return this.$Message.error("请先打开视频！");
            // }
            videoKit.startRecord();
            this.isRecording = true
        },
        stopRecord() {
            videoKit.stopRecord()
            this.isRecording = false
        }
    },
    created() {
        // 获取萤石云 监控token
        getElevatorMonitorUrlEzopenToken().then(res => {
            console.log("res 2", res)
            this.privateUrlToken = res.info
        })
    },
    beforeDestroy() {
        videoKit.resetPlayer()
    },
    watch: {
        elevator(newData, oldData) {
            let hasPlayer = videoKit.pickPlayer(this.domContainer)
            if (newData != null && newData != undefined) {
                if (!Boolean(hasPlayer)) {
                    this.startMonitor()
                } else {
                    this.loadingInstance = Loading.service()
                    getElevatorLiveUrl({ elevatorId: newData['v_elevator_id'] }).then(res => {
                        if (this.showMonitor) {
                            this.closeConnection()
                            if (res.info)
                                this.initSocket(res.info.vElevatorCode, ['CarRoof', 'MontorRoom', 'SINGLEBOX'])
                        }
                        if (res.code === '0000') {
                            this.currentCamera = res.info
                        }
                        this.changeVideoUrl()
                    }).catch((err) => {
                        this.loadingInstance.close()
                    })
                    this.isVideoInit = true
                    // if (newData['v_elevator_id'] != oldData['v_elevator_id']) {
                    // } 
                }
            } else {
                this.stopVideo()
            }
        }
    }
}
</script>
<style lang="less" scoped>
svg {
    display: none;
}
.video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #383C4B;
}

.btn-block {
    display: inline-block;
    float: right;
    color: #D6D8E4;
    font-size: 16px;
    background-color: #383C4B;
    border: 1px solid #606371;
    border-radius: 5px;
    width: 128px;
    height: 33px;
    line-height: 33px;
    margin-right: 8px;
    text-align: center;

}

.btn-block img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: sub;
}

.video-seat {
    width: 100%;
    height: auto;
    position: absolute;
    top: 1px;
    right: 1px;
    padding: 15px 8px;
    z-index: 999999;
    background-color: rgba(0, 0, 0, .3);
}

.video-poster {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.video-poster img {
    width: 90px;
    height: 90px;
}

.video-zone {
    width: 100%;
    height: 100%;

    iframe {
        width: 100% !important;
    }
}

.elevator-name-span {
    color: #fff;
    font-size: 20px;
    text-align: left;
    padding-left: 10px;
}

.options {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    text-align: center;
    // background-image:url('@/assets/bigscreen/ruijin/options_bg.png');
    // background-size: 100% 100%;
    // background-color: #011145;
    background-size: auto 100%;

    &>.item {
        display: inline-block;
        padding: 0 20px;
        text-align: center;

        .radio {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 5px solid #fff;
            border-radius: 100%;
            background-color: #fff;
            overflow: hidden;
        }

        .title {
            font-size: 16px;
            color: #fff;
        }

        &.active {
            .radio {
                border-color: #de1717;
                background-color: #0d1b4c;
            }
        }
    }
}
</style>
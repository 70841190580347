import EZUIKit from 'ezuikit-js';

const ezopenBaseUrl = "ezopen://open.ys7.com";

let player = null;
let initedPlayers = {};
let timerMapping = {}
export function initDisplay(domContainer, accessToken, serialNum, option = {}, callback) {

    let _player = new EZUIKit.EZUIKitPlayer({
        autoplay: false,
        audio: 1,
        id: domContainer,
        accessToken: accessToken,
        url: generateLiveUrl(serialNum, option),
        template: 'standard',
        plugin: option['talk'] || ['talk'],
        header: option['header'],
        footer: option['footer'],
        // themeData: {
        //     "header": {
        //         "color": "#1890ff",
        //         "activeColor": "#FFFFFF",
        //         "backgroundColor": "#000000",
        //         "btnList": []
        //     },
        //     "footer": {
        //         "color": "#FFFFFF",
        //         "activeColor": "#1890FF",
        //         "backgroundColor": "#00000021",
        //         "btnList": [{
        //                 "iconId": "play",
        //                 "part": "left",
        //                 "defaultActive": 0,
        //                 "memo": "播放",
        //                 "isrender": 1
        //             },
        //             {
        //                 "iconId": "capturePicture",
        //                 "part": "left",
        //                 "defaultActive": 0,
        //                 "memo": "截屏按钮",
        //                 "isrender": 1
        //             },
        //             {
        //                 "iconId": "sound",
        //                 "part": "left",
        //                 "defaultActive": 0,
        //                 "memo": "声音按钮",
        //                 "isrender": 1
        //             },
        //             {
        //                 "iconId": "recordvideo",
        //                 "part": "left",
        //                 "defaultActive": 0,
        //                 "memo": "录制按钮",
        //                 "isrender": 1
        //             },
        //             {
        //                 "iconId": "expend",
        //                 "part": "right",
        //                 "defaultActive": 0,
        //                 "memo": "全局全屏按钮",
        //                 "isrender": 1
        //             }
        //         ]
        //     }
        // },
        handleSuccess: data => {
            console.log("播放成功", data)
            if (timerMapping[data.id]) {
                clearTimeout(timerMapping[data.id])
                timerMapping[data.id] = null
            }
            let timer = setTimeout(function() {
                let _p = pickPlayer(domContainer)
                _p.stop()
            }, 120000);
            timerMapping[data.id] = timer

        },
        handleError: data => {
            if (callback) callback(false, data)
        },
        openSoundCallBack: data => console.log("开启声音回调", data),
        closeSoundCallBack: data => console.log("关闭声音回调", data),
        startSaveCallBack: data => console.log("开始录像回调", data),
        stopSaveCallBack: data => console.log("录像回调", data),
        capturePictureCallBack: data => console.log("截图成功回调", data),
        fullScreenCallBack: data => console.log("全屏回调", data),
    })

    player = _player;
    initedPlayers[domContainer] = _player;

    return new Promise(function(resolve, reject) {
        if (player) {

            resolve(player)
        } else {
            reject("not found player instance")
        }
    })
}

export function generateLiveUrl(serialNum, option) {
    let url = `${ezopenBaseUrl}/${serialNum}/${option.channel || 1}.${option.quality != null ? option.quality + '.' : ''}${option.way || 'live'}`
    console.log("ezopen url = ", url)
    return url
}

export function stopVideo() {
    return player.stop().then((option) => {
        console.log("视频播放停止成功！", option)
    });
}

export function playVideo() {
    return player.play().then((option) => {
        console.log("视频播放成功", option)
    });
}

export function refreshVideo() {
    player.stop().then((option) => {
        console.log("视频播放停止成功！", option)
        player.play().then((option) => {
            console.log("视频播放成功", option)
        });
    });
}

export function changeVideoUrl(serialNum, option = {}) {
    return new Promise(function(resolve, reject) {
        player.stop().then(() => {
            let newUrl = generateLiveUrl(serialNum, option);
            player.play(newUrl).then((option) => {
                console.log("视频切换成功！", option);
                player.stop().then(() => {
                    resolve()
                }).catch(() => {
                    reject()
                });
            });
        }).catch(() => {
            reject()
        })
    })
}

export function startTalk() {
    player.startTalk()
    console.log("打开对话成功！");
    return new Promise(function(resolve, reject) {
        if (player) {
            resolve()
        } else {
            reject("not found player instance")
        }
    })
}

export function stopTalk() {
    player.stopTalk()
    console.log("停止对话成功！");
    return new Promise(function(resolve, reject) {
        if (player) {
            resolve()
        } else {
            reject("not found player instance")
        }
    })
}

export function startRecord() {
    player.startSave();
}

export function stopRecord() {
    player.stopSave();
}

export function reSize(width = null, height = null) {
    player.reSize(width, height);
}

export function destroy() {
    player = null
    if (!player)
        console.log("player destoried")
}

export function checkPlayer() {
    return Boolean(player)
}

export function pickPlayer(domContainer) {
    player = initedPlayers[domContainer]
    return player
}

export function resetPlayer() {
    initedPlayers = {}
}

export default { initDisplay, playVideo, refreshVideo, stopVideo, changeVideoUrl, stopTalk, startTalk, checkPlayer, destroy, reSize, pickPlayer, startRecord, stopRecord, resetPlayer }